@import url(https://fonts.googleapis.com/css?family=Amatic+SC&display=swap);
.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh; }
  .Home h1 {
    margin-top: -90px;
    font-size: 90px;
    font-family: "Amatic SC", cursive;
    white-space: nowrap; }

.ScheduleRetake {
  margin: 16px; }

label {
  display: block;
  font-weight: bold; }

.formItem {
  margin-bottom: 16px; }

.InquiryCube {
  background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0; }
  .InquiryCube h1 {
    color: white; }

#cube-scene {
  margin-top: 16px;
  width: 90%;
  overflow: hidden;
  height: 50vh; }

