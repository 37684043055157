@import "~semantic-ui-css/semantic.min.css";
@import url("https://fonts.googleapis.com/css?family=Amatic+SC&display=swap");

.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;

  h1 {
    margin-top: -90px;
    font-size: 90px;
    font-family: "Amatic SC", cursive;
    white-space: nowrap;
  }
}

.ScheduleRetake {
  margin: 16px;
}

label {
  display: block;
  font-weight: bold;
}

.formItem {
  margin-bottom: 16px;
}

.InquiryCube {
  background-color: black;
  height: 100vh;

  h1 {
    color: white;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

#cube-scene {
  margin-top: 16px;
  width: 90%;
  overflow: hidden;
  height: 50vh;
}
